<form [formGroup]="rangeForm">
  <mat-form-field appearance="outline">
    <mat-date-range-input class="w-100" formGroupName="dateRange" [rangePicker]="picker">
      <input formControlName="start" class="align-middle" matStartDate placeholder="Today"/>
      <input formControlName="end" matEndDate />
    </mat-date-range-input>
    <mat-datepicker-toggle class="justify-content-start" [for]="picker">
      <ng-container matDatepickerToggleIcon>
        <mat-icon>calendar_month</mat-icon>
      </ng-container>
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>
