import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ICountry } from "src/app/@interfaces/country.interface";
import { ProviderService } from "src/app/@pages/provider/provider.service";
import { ValidatorsService } from "src/app/@shared/validator/validators.service";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { IDocumenType } from "../../../../../@interfaces/authentication/documenType";
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { map, startWith, takeUntil } from "rxjs/operators";
import { BillyCities } from "../../../../../@interfaces/Billy/billy.interface";
import { IDeclarantTypes } from "../../../../../@interfaces/declarantTypes.interface";
import { IRegimeTypes } from "src/app/@interfaces/regimeTypes.interface";
import { CustomerService } from "../../../../../@pages/customer/customer.service";
import { ICompanyType, IStorageCompany } from "../../../../../@interfaces/company.interface";
import { IContributorType } from "src/app/@interfaces/contributorTypes.interface";
import { UsersService } from "src/app/@pages/users/users.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { CitiesFormService } from "../../../services/cities-form.service";

@Component({
  selector: "app-register-provider",
  templateUrl: "./register-provider.component.html",
  styleUrls: ["./register-provider.component.css"],
})
export class RegisterProviderComponent implements OnInit, OnDestroy {
  cityList: string[] = [];
  filterCities$: Observable<string[]> = new Observable();
  billyCities = {} as BillyCities;
  countryList: Array<ICountry> = [];
  countrySelected: string = "";
  documenTypeList: Array<IDocumenType> = [];
  contributorTypeList: Array<IContributorType> = [];
  declarantsList: Array<IDeclarantTypes> = [];
  regimeList: Array<IRegimeTypes> = [];
  companyTypeList: Array<ICompanyType> = [];
  providerForm: UntypedFormGroup = this.fb.group({
    doc_nit: ["", [Validators.required, Validators.pattern(this.validatorS.documentPattern)]],
    types_docs: ["", [Validators.required]],
    name: ["", [Validators.required, Validators.maxLength(60)]],
    address: ["", [Validators.required, Validators.maxLength(100)]],
    phone: ["",[ Validators.required, Validators.pattern(this.validatorS.documentPattern), Validators.maxLength(15),],],
    email: ["",[Validators.required, Validators.pattern(this.validatorS.emailPattern), Validators.maxLength(50),],],
    country: ["", Validators.required],
    city: ["", Validators.required],
    citySelect: [""],
    contributorType: ["", [Validators.required]],
    regime: [""],
    declarant: [""],
    type: [""],
    activity_code: [""],
  });

  companyId = '';
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  companyObject = {} as IStorageCompany;

  constructor(
    private translate: TranslateService,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private router: Router,
    private fb: UntypedFormBuilder,
    private validatorS: ValidatorsService,
    private providerService: ProviderService,
    private customerService: CustomerService,
    private userService: UsersService,
    private cookieAuthService: CookieAuthService,
    private citiesFormService: CitiesFormService
  ) {}

  async ngOnInit() {
    this.getAuthValues();
    this.customerService
    .listCountries()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.countryList = result;
    });

    this.userService
    .listDocumenTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.documenTypeList = result;
    });

    this.providerService
    .getAllRegimeTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.regimeList = result;
    });

    this.providerService
    .getAllCompanyTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.companyTypeList = result;
    });

    this.customerService
    .listContributorTypes()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result: any) => {
      this.contributorTypeList = result;
    })

    if (this.companyObject.countries[0].name === "Colombia") {
      this.setValid();
    }
    this.citiesFromStore();
  }

  citiesFromStore() {
    this.citiesFormService.setInitialCitiesFromStore();
    this.providerForm.get('country')?.valueChanges.subscribe((result) => {
      this.spinnerService.show();
      this.setCity(result)
    })
    this.filterCities$ = this.providerForm.get('citySelect')!.valueChanges.pipe(
      startWith(""),
      map((value) => this.citiesFormService.filterCities(value, this.cityList))
    );
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  setValid() {
    this.providerForm.get("regime")?.setValidators(Validators.required);
    this.providerForm.get("declarant")?.setValidators(Validators.required);
    this.providerForm.get("type")?.setValidators(Validators.required);
    this.providerForm.get("activity_code")?.setValidators(Validators.required);
  }

  register() {
    this.providerForm.markAllAsTouched();
    if (this.providerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Please, fill in all the required fields!"),
      });
      return;
    } else {
      this.spinnerService.show();
      this.saveOnDataBase();
    }
  }

  saveOnDataBase() {
    this.providerService
    .registerProvider(
      this.providerForm.value.doc_nit,
      parseInt(this.providerForm.value.types_docs.id_docs),
      this.providerForm.value.name,
      this.providerForm.value.address,
      this.providerForm.value.phone,
      this.providerForm.value.email,
      parseInt(this.providerForm.value.country.id_country),
      this.providerForm.value.city,
      parseInt(this.providerForm.value.contributorType.id_contributor),
      parseInt(this.providerForm.value.regime.id_regime),
      parseInt(this.providerForm.value.declarant.id_declarant),
      parseInt(this.providerForm.value.type.id_type),
      this.providerForm.value.activity_code,
      parseInt(this.user!)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result.nombre !== "providerExist") {
        this.spinnerService.hide();
        Swal.fire(
          this.translate.instant("success"),
          this.translate.instant("Provider created successfully"),
          "success"
        ).then((result) => {
          if (result.isConfirmed) {
            this.router
            .navigateByUrl("/table", { skipLocationChange: true })
            .then(() => {
              this.router.navigate(["/provider"]);
            });
          }
        });
      } else if (result.nombre === "providerExist") {
        this.spinnerService.hide();
        Swal.fire(
          this.translate.instant("Provider was not created"),
          this.translate.instant("This provider already exist"),
          "warning"
        );
      } else {
        this.spinnerService.hide();
        Swal.fire(
          "error",
          this.translate.instant("Something was wrong"),
          "error"
        );
      }
    });
  }

  textValid(text: string) {
    return (
      this.providerForm.get(text)?.invalid &&
      this.providerForm.get(text)?.touched
    );
  }

  filterDeclarantList() {
    this.providerService
    .getDeclarantByRegime(parseInt(this.providerForm.value.regime.id_regime))
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.declarantsList = result;
    });
  }

  async setCity(country: any) {
    this.countrySelected = country.iso_num;
    if (this.countrySelected === "170") {
      this.billyCities = await this.citiesFormService.getBillyCities(this.countrySelected);
      this.spinnerService.hide();
    } else {
      this.cityList = await this.citiesFormService.getCityList(country);
      this.spinnerService.hide();
    }
  }

  getCitySelect() {
    if (this.providerForm.value.country.iso_num === "170") {
      this.providerForm
      .get("city")
      ?.setValue(this.providerForm.value.citySelect?.attributes.name);
    } else {
      this.providerForm
      .get("city")
      ?.setValue(this.providerForm.value.citySelect);
    }
  }

  onSelectProvider(city: string) {
    this.providerForm.get("city")?.setValue(city);
  }

  displayFnCity(city: any) {
    return city && city ? city : undefined;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
