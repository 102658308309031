import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { WarehouseService } from 'src/app/@pages/warehouse/warehouse.service';
import { AlertsService } from '../../services/alerts.service';
import { InventoryService } from 'src/app/@pages/inventory/inventory.service';
import { ErrorsService } from '../../services/errors.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { IWarehouse } from 'src/app/@interfaces/warehouse.interface';
import { IArticles } from 'src/app/@interfaces/article.interface';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ArticleCostService } from '../../services/article-cost.service';
import { CookieAuthService } from 'src/app/@shared/storage-variables/cookie-auth.service';

@Component({
  selector: 'app-crud-inventory-reclassification',
  templateUrl: './crud-inventory-reclassification.component.html',
  styleUrls: ['./crud-inventory-reclassification.component.css']
})
export class CrudInventoryReclassificationComponent implements OnInit, OnDestroy {
  dateForm: UntypedFormControl = this.fb.control(new Date(), Validators.required);
  reclassificationForm:  UntypedFormGroup = this.fb.group({
    exitArticle: ["", [Validators.required]],
    entryArticle: ["", [Validators.required]],
    exitWarehouse: ["", [Validators.required]],
    entryWarehouse: ["", [Validators.required]],
    value: [0, [Validators.required, Validators.min(0.01), Validators.max(99999999.99)]],
  });
  warehouseExitFilter: Observable<IWarehouse[]> | undefined;
  warehouseEntryFilter: Observable<IWarehouse[]> | undefined;
  warehouseList: IWarehouse[] = [];
  articleList: IArticles[] = [];
  unsubscribe$ = new Subject();
  user = '';
  constructor(
    private fb: UntypedFormBuilder,
    private warehouseService: WarehouseService,
    private spinnerService: NgxSpinnerService,
    private alertsService: AlertsService,
    private inventoryService: InventoryService,
    private errorsService: ErrorsService,
    private articleCostService: ArticleCostService,
    private cookieAuthService: CookieAuthService
  ) { }

  async ngOnInit() {
    this.getAuthValues();
    this.data();
    this.filterInputs();
  }
  
  getAuthValues() {
    this.user = this.cookieAuthService.getUserId!;
  }

  data() {
    this.warehouseService
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    })
    this.findArticles();
  }

  findArticles() {
    this.reclassificationForm
    .get("exitArticle")?.valueChanges
    .subscribe(async (result) => {
      if(!result.__typename) {
        this.articleList = await this.searchArticle(result);
      }
    })
    this.reclassificationForm
    .get("entryArticle")?.valueChanges
    .subscribe(async (result) => {
      if(!result.__typename) {
        this.articleList = await this.searchArticle(result);
      }
    })
  }

  searchArticle(value: string) {
    return new Promise<IArticles[]>((resolve, reject) => {
      this.inventoryService
      .searchArticle(value, null, null, null)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result.articles);
      })
    })
  }

  filterInputs() {
    this.warehouseExitFilter = 
    this.reclassificationForm
    .get("exitWarehouse")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );

    this.warehouseEntryFilter = 
    this.reclassificationForm
    .get("entryWarehouse")?.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterP(value))
    );
  }

  _filterP(value: string): IWarehouse[] {
    const filterValueP = value.toString().toLowerCase();
    return this.warehouseList.filter((option) =>
      option.nombre.toLowerCase()
      .toString().includes(filterValueP)
    );
  }

  displayFnWarehouse(warehouse: any) {
    return warehouse && warehouse ? warehouse.nombre : undefined;
  }
  
  displayFnArticle(article: any) {
    return article && article ? article.descripcion : undefined;
  }

  register() {
    this.spinnerService.show();
    if (this.reclassificationForm.invalid) {
      this.spinnerService.hide();
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }else {
      const date = this.dateForm.value;
      this.warehouseService.createInventoryReclassification(
        parseInt(this.reclassificationForm.value.exitArticle.id_articulo),
        parseInt(this.reclassificationForm.value.entryArticle.id_articulo),
        parseInt(this.reclassificationForm.value.exitWarehouse.id_almacen),
        parseInt(this.reclassificationForm.value.entryWarehouse.id_almacen),
        new Date(date).toDateString(),
        this.reclassificationForm.value.value,
        parseInt(this.user!)
      ).pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.afterCreated();
      },(error) => {
        this.onCreatedError(error);
      })
    }
  }

  afterCreated() {
    this.spinnerService.hide();
    this.alertsService.showSuccessfullAlert("Reclassification Created").then((result) => {
      if (result.isConfirmed) {
        this.alertsService.refresh("/table", "/inventory_movements")
      }
    });
  }

  onCreatedError(error: any) {
    const errorTransformed = this.errorsService.getErrorFromJson(error)
    this.spinnerService.hide();
    this.alertsService.showErrorAlert(errorTransformed.message.split(':')[1].trim())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
