import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IArticles } from 'src/app/@interfaces/article.interface';
import { IWarehouse } from '../../../../../@interfaces/warehouse.interface';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { InventoryService } from 'src/app/@pages/inventory/inventory.service';
import { WarehouseService } from '../../../../../@pages/warehouse/warehouse.service';
import { takeUntil } from 'rxjs/operators';
import { AlertsService } from '../../../services/alerts.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog.component';
import { IWarehouseArticle } from 'src/app/@interfaces/warehouseArticle.interface';
import { ArticleCostService } from '../../../services/article-cost.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-warehouse-openings',
  templateUrl: './warehouse-openings.component.html',
  styleUrls: ['./warehouse-openings.component.css']
})
export class WarehouseOpeningsComponent implements OnInit, OnDestroy {
  @Input() article = {} as IArticles;
  warehouseList: IWarehouse[] = [];
  warehouseForm = new UntypedFormGroup({
    warehouse: new UntypedFormControl('', Validators.required),
    value: new UntypedFormControl(0),
    quantity: new UntypedFormControl(0),
  });
  unsubscribe$ = new Subject();
  constructor(
    private inventoryService: InventoryService,
    private warehouseService: WarehouseService,
    private alertsService: AlertsService,
    private dialog: MatDialog,
    private articleCostService: ArticleCostService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.data();
  }

  data() {
    this.warehouseService.listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    })
  }

  create() {
    this.inventoryService
    .setWarehouseOpening(
      this.article.id_articulo,
      parseInt(this.warehouseForm.value.warehouse.id_almacen),
      parseFloat(this.warehouseForm.value.quantity),
      parseFloat(this.warehouseForm.value.value),
    ).pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.alertsService.showSuccessfullAlert("The warehouse was correctly related")
      .then((confirm) => {
        if (confirm.isConfirmed) {
          this.updateData();
          this.alertsService.refresh("/dialog", "/inventory");
        }
      })
    }, (error) => {
      this.alertsService.showErrorAlert("Something was wrong")
      .then((confirm) => {
        console.error(error);
      });
    })
  }

  update(opening: IWarehouseArticle) {
    this.dialog.open(DialogComponent, {
      data: { modifyWarehouseOpenings: true, opening: opening }
    }).afterClosed().pipe().subscribe((closed) => {
      this.updateData();
    })
  }

  delete(opening: IWarehouseArticle) {
    this.inventoryService.deleteWarehouseOpening(
      opening.id_warehouse_article
    ).pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.alertsService.showSuccessfullAlert("The warehouse was correctly deleted")
      .then((confirm) => {
        if (confirm.isConfirmed) {
          this.updateData();
          this.alertsService.refresh("/dialog", "/inventory");
        }
      })
    }, (error) => {
      this.alertsService.showErrorAlert("Something was wrong")
      .then((confirm) => {
        console.error(error);
      });
    })
  }

  updateData() {
    this.inventoryService.getArticle(
      this.article.id_articulo
    ).pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.article = result;
    })
  }

  textValid(text: string) {
    return (
      this.warehouseForm.get(text)?.invalid && this.warehouseForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
