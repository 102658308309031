import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DateFilterComponent implements OnInit {
  rangeForm!: FormGroup;
  @Output() datepickerRange = new EventEmitter<{ start: string | null; end: string | null, valid: boolean }>();

  constructor(private fb: FormBuilder, private translate: TranslateService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.rangeForm = this.fb.group({
      dateRange: this.fb.group({
        start: [new Date().setHours(0, 0, 0, 0), Validators.required],
        end: [new Date().setHours(0, 0, 0, 0), Validators.required]
      })
    })
    const rangeForm = this.rangeForm.get('dateRange');
    const startValue = rangeForm?.get('start')?.value
    const endValue = rangeForm?.get('end')?.value
    if (startValue === new Date().setHours(0, 0, 0, 0) && endValue === new Date().setHours(0, 0, 0, 0)) {
      const defaultValue = this.translate.instant('Today')
      rangeForm?.patchValue({ start: defaultValue })
    }
    rangeForm?.valueChanges.subscribe((date) => {
      const eventValue = { ...date, valid: rangeForm?.valid };
      this.getDatepickerRange(eventValue);
    })
  }

  getDatepickerRange(value: { start: Date | null; end: Date | null; valid: boolean }) {
    const date = { 
      start: this.datePipe.transform(value.start, 'M-d-yyyy'),
      end: this.datePipe.transform(value.end, 'M-d-yyyy'), 
      valid: value.valid
    }
    this.datepickerRange.emit(date);
  }
}
