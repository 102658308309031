import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { CancelImportsService } from "./cancel-imports.service";
import { AlertsService } from './alerts.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { INotasGrap } from "src/app/@interfaces/notas.interface";
import { IImportNotes } from "src/app/@interfaces/Import-files/import-notes.interface";
import { ArticleCostService } from './article-cost.service';
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Injectable({
    providedIn: 'root'
})

export class ImportNotesService {
    unsubscribe$ = new Subject();
    
    constructor(
        private quotationService: QuotationsService,
        private translate: TranslateService,
        private cancelImportsService: CancelImportsService,
        private alertsService: AlertsService,
        private articleCostService: ArticleCostService,
        private cookieAuthService: CookieAuthService
    ) {
    }

    createNote(noteObject: IImportNotes) {
        const user = this.cookieAuthService.getUserId!;
        return new Promise<string>(async (resolve, reject) => {
            if (noteObject.articleCode.length > 0) {
                const initNumber = this.cancelImportsService.companyInvoiceName();
                const typeValid = this.validNoteTypes(noteObject.noteType);
                const noteExitst: boolean = await this.validBillyNote(initNumber + noteObject.noteID.toString(), noteObject.invoiceYear);
                if (!noteExitst && typeValid) {
                    const idInvoice = await this.cancelImportsService.getInvoiceIDFromCollections(noteObject.invoiceID, noteObject.invoiceYear);
                    this.quotationService
                    .createNote(
                        parseInt(idInvoice.toString()),
                        noteObject.warehouseID,
                        [1],
                        noteObject.articleCode,
                        noteObject.quantity,
                        this.translate.instant(noteObject.noteType),
                        noteObject.concept,
                        noteObject.price,
                        noteObject.subtotal,
                        noteObject.discount,
                        noteObject.total[0],
                        noteObject.createdDate,
                        noteObject.dueDate,
                        parseInt(user)
                    ).pipe(takeUntil(this.unsubscribe$))
                    .subscribe((note: INotasGrap) => {
                        resolve(this.onCreateSuccessfull(noteObject, note, initNumber));
                    },(error) => {
                        reject(this.onCreateError(noteObject.noteID, error));
                    })
                }else {
                    resolve("Item id:" + " " + "[ " + noteObject.noteID.toString() + " ]" + " >>>>>>>>>>>> " + "Already Exitst");
                }
            }else {
                this.alertsService.showErrorAlert('Error constructing the note' + ':' + noteObject.noteID)
                .then((confirm) => {
                    if (confirm.isConfirmed) {
                        reject('Error constructing the note');
                    }
                });
            }
        })
    }

    onCreateError(noteID: number, error: string) {
        this.alertsService.showErrorAlert('Error uploading note' + ":" + noteID + error)
        .then((confirm) => {
            if(confirm.isConfirmed) {
                return(error);
            }else {
                return('')
            }
        })
    }

    onCreateSuccessfull(noteObject: IImportNotes, resultNote: INotasGrap, initNumber: string) {
        const companyObject = this.cookieAuthService.getCompanyObject;
        if (companyObject!.countries[0].name === "Colombia") {
            this.quotationService
            .setBillyNote(
                resultNote.id_nota!.toString(), initNumber + 
                noteObject.noteID.toString(), initNumber + noteObject.noteID.toString()
            ).pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {});
        }
        return "Item id:" + " " + "[ " + noteObject.noteID.toString() + " ]" + " >>>>>>>>>>>> " + "OK";
    }

    validBillyNote(noteNumber: string, year: number) {
        const companyObject = this.cookieAuthService.getCompanyObject;
        return new Promise<boolean>((resolve, reject) => {
            if (companyObject!.countries[0].name === "Colombia") {
              this.quotationService
              .validBillyNote(noteNumber, year)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((result: boolean) => {
                resolve(result);
              });
            } else {
              resolve(false);
            }
        });
    }

    validNoteTypes(noteType: string) {
        return noteType === 
        this.translate.instant('Credito') ||
        this.translate.instant('Debit') ?
        true : false;
    }

    converseValuesByType(value: number, type: string) {
        if(type === this.translate.instant("Debit")) {
            return parseFloat(value.toString().trim());
        }else {
            return parseFloat(value.toString().trim()) * -1;
        }
    }
}