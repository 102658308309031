import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { startWith, map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { IArticles } from "src/app/@interfaces/article.interface";
import { ICustomer } from "src/app/@interfaces/customer.interface";
import { QuotationsService } from "src/app/@pages/quotations/quotations.service";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { NgxSpinnerService } from "ngx-spinner";
import { DialogComponent } from "../../../dialog.component";
import { INotas } from "src/app/@interfaces/notas.interface";
import { Concept } from "../../../../../../@interfaces/notas.interface";
import {
  IBillyCreditNote,
  IGraphQToBilly,
  IBillyNotesToGraphQ,
} from "../../../../../../@interfaces/Billy/billy.interface";
import { BillyNotesService } from "../../billyNotes.service";
import { IQuota } from "../../../../../../@interfaces/quotation.interface";
import { AlertsService } from '../../../../services/alerts.service';
import { ArticleCostService } from '../../../../services/article-cost.service';
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from '../../../../../../@interfaces/company.interface';

@Component({
  selector: "app-credit-note",
  templateUrl: "./credit-note.component.html",
  styleUrls: ["./credit-note.component.css"],
})
export class CreditNoteComponent implements OnInit, OnDestroy {
  @Input() id_fact: number = 0;
  quotationToModify = {} as IQuota;
  dateToday = new Date();
  totalQuot: number = 0;
  totalQuot2: number = 0;
  newCustomer: UntypedFormControl = this.fb.control("", Validators.required);
  newArticle: UntypedFormControl = this.fb.control("", Validators.required);
  registerForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array(
      [
        this.fb.group({
          ids: [],
          article: this.newArticle,
          quantity: [, Validators.required],
          quantity2: [, Validators.required],
          price: [, Validators.required],
          price2: [, Validators.required],
          discount: [, Validators.required],
          subtotal: [0],
          subtotal2: [0],
          subtotal3: [0],
          warehouse: ["", Validators.required],
          subtotalVec: [0],
          cantidadVec: [0],
          precioVec: [0],
        }),
      ],
      Validators.required
    ),
    tax_incl: false,
    tax: [, Validators.required],
    date: [this.dateToday, Validators.required],
    dueDate: [this.dateToday, Validators.required],
    concepto: ["", Validators.required],
  });
  taxVer: number = 0;
  totalCalc: number = 0;
  get rowsArr() {
    return this.registerForm.get("itemRows") as UntypedFormArray;
  }
  filteredOptionsArticle: Observable<IArticles[]> | undefined;
  filteredOptionsCustomer: Observable<ICustomer[]> | undefined;
  setNota = {} as INotas;
  concept: Concept[] = [
    { value: "Discount", viewValue: "Discount (Partial)" }, //Parcial
    { value: "Emission by error", viewValue: "Emission by error (Total)" }, //Total
    {
      value: "Correction of values",
      viewValue: "Correction of values (Partial)",
    }, //Parcial
    {
      value: "Correction of quantities",
      viewValue: "Correction of quantities (Partial)",
    }, //Parcial
    {
      value: "The invoice will not be paid",
      viewValue: "The invoice will not be paid (Total)",
    }, //Total
    { value: "Customer return", viewValue: "Customer return (Total)" }, // Total
    {
      value: "Difference from actual price to price charged",
      viewValue: "Difference from actual price to price charged (Partial)",
    }, //Parcial
  ];
  Sales: Array<IVentaQuery> = [];
  ArticleList: Array<IArticles> = [];
  CustomersList: Array<ICustomer> = [];
  WarehouseList: Array<IWarehouse> = [];
  billyNote?: IBillyCreditNote;
  billyGraphQL?: IGraphQToBilly;
  user = '';
  rol = '';
  companyId = '';
  companyObject = {} as IStorageCompany;
  unsubscribe$ = new Subject();

  constructor(
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public quotation: QuotationsService,
    public warehouseS: WarehouseService,
    private fb: UntypedFormBuilder,
    private billyNoteService: BillyNotesService,
    private alertsService: AlertsService,
    private articleCostService: ArticleCostService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner();
    this.getAuthValues();
    this.filteredValues();
    this.quotationToModify = await this.getQuotation();
    this.setQuotation(this.quotationToModify);
    this.setNota.valores = [{
      id_articulo: [],
      id_warehouse: [],
      codigo: [],
      cantidad: [],
      precio: [],
      subtotal: [],
      descuento: [],
    }]
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject!.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  filteredValues() {
    this.filteredOptionsArticle = this.newArticle.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterA(value))
    );

    this.filteredOptionsCustomer = this.newCustomer.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
  }

  getQuotation() {
    return new Promise<IQuota>((resolve, reject) => {
      this.quotation
      .getQuotation(this.id_fact)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result)
      })
    })
  }

  setQuotation(quotation: IQuota) {
    const { cliente } = quotation;
    this.Sales = quotation.venta;
    const [parameter] = this.Sales;
    const { total } = parameter;
    this.totalQuot = total;
    this.editQuotation(quotation, this.Sales, cliente);
  }

  editQuotation(quotation: IQuota, sales: IVentaQuery[], customer: ICustomer[]) {
    let taxBool: boolean;
    if (quotation.tax_incl === "true") {
      taxBool = true;
    } else {
      taxBool = false;
    }
    this.registerForm.patchValue({
      tax: quotation.tax,
      tax_incl: taxBool,
    });
    this.newCustomer.setValue(customer[0]);
    this.registerForm.setControl("itemRows", this.setVenta(sales));
  }

  setVenta(saleSets: IVentaQuery[]): UntypedFormArray {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < saleSets.length; index++) {
      formArray.push(
        this.fb.group({
          ids: saleSets[index].id_venta,
          article: saleSets[index].articulo[0],
          quantity: saleSets[index].cantidad,
          quantity2: saleSets[index].cantidad,
          discount: saleSets[index].descuento,
          price: saleSets[index].precio,
          price2: saleSets[index].precio,
          subtotal: saleSets[index].subtotal,
          subtotal2: saleSets[index].subtotal,
          warehouse: saleSets[index].almacen![0],
          subtotal3: 0,
          subtotalVec: 0,
          cantidadVec: 0,
          precioVec: 0,
        })
      );
    }
    return formArray;
  }

  async disableInputs(index: number, control: string, disableControl: string, originalControl: string) {
    const originalValue = this.rowsArr.controls[index].get(originalControl)?.value;
    const currentValue = await this.getInputValue(index, control);
    if (parseFloat(currentValue) !== originalValue) {
      this.rowsArr.controls[index].get(disableControl)?.disable();
    } else {
      this.rowsArr.controls[index].get(disableControl)?.enable();
    }
  }

  getInputValue(index: number, control: string) {
    return new Promise<string>((resolve, reject) => {
      this.rowsArr.controls[index].get(control)?.valueChanges.subscribe((value) => {
        resolve(value)
      })
    })
  }

  changeValues() {
    let concepto;
    concepto = this.registerForm.value.concepto;
    if (concepto === "Emission by error" || concepto === "The invoice will not be paid" || concepto === "Customer return") {
      for (let i = 0; i < this.rowsArr.length; i++) {
        this.rowsArr.at(i).get("price")?.setValue(0);
        this.rowsArr.at(i).get("quantity")?.setValue(0);
      }
      this.calculation();
    } else {
      this.calculation();
    }
  }

  crear() {
    if (this.registerForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      return;
    }
    for (let index = 0; index < this.rowsArr.value.length; index++) {
      if (parseFloat(this.rowsArr.value[index].subtotal.toFixed(2)) > parseFloat(this.rowsArr.value[index].subtotal2.toFixed(2))) {
        this.alertsService.showErrorAlert("The subtotal cannot be higher than the current one.!")
        return;
      }
      if (this.rowsArr.value[index].quantity > this.rowsArr.value[index].quantity2) {
        this.alertsService.showErrorAlert("If you wish to add more products, please create a new invoice.!")
        return;
      }
      if (parseFloat(this.rowsArr.value[index].subtotalVec) < 0) {
        this.setNota.valores[0].id_warehouse.push(
          parseInt(this.rowsArr.value[index].warehouse.id_almacen)
        );
        this.setNota.valores[0].id_articulo.push(
          parseInt(this.rowsArr.value[index].article.id_articulo)
        );
        this.setNota.valores[0].codigo.push(
          this.rowsArr.value[index].article.codigo
        );
        if (Math.sign(parseFloat(this.rowsArr.value[index].cantidadVec)) === -1) {
          this.setNota.valores[0].cantidad.push(
            parseFloat(this.rowsArr.value[index].cantidadVec)
          );
        } else if (Math.sign(parseFloat(this.rowsArr.value[index].cantidadVec)) === 1) {
          this.setNota.valores[0].cantidad.push(
            parseFloat(this.rowsArr.value[index].cantidadVec) * -1
          );
        }
        this.setNota.valores[0].precio.push(
          parseFloat(this.rowsArr.value[index].precioVec)
        );
        this.setNota.valores[0].subtotal.push(
          parseFloat(this.rowsArr.value[index].subtotalVec)
        );
        this.setNota.valores[0].descuento.push(
          parseFloat(this.rowsArr.value[index].discount)
        );
      }
    }
    this.setNota.note_fact = parseInt(this.id_fact.toString());
    this.setNota.tipo_nota = "Credito";
    this.setNota.concepto = this.registerForm.value.concepto;
    this.setNota.createAt = this.registerForm.value.date;
    this.setNota.dueDate = this.registerForm.value.dueDate;
    this.setNota.total = this.totalQuot2;
    const variables = this.setNota;
    if (this.companyObject.external_electronic_invoicing === "true") {
      this.spinnerService.show();
      this.billyNoteService.sendDian(variables).then((result) => {
        if (result.confirm) {
          this.saveOnDataBase(variables, result.data);
        }
      });
    } else {
      this.spinnerService.show();
      this.saveOnDataBase(variables);
    }
  }

  saveOnDataBase(noteToCreate: INotas, billyNote?: IBillyNotesToGraphQ) {
    this.quotation
    .createNote(
      noteToCreate.note_fact,
      noteToCreate.valores[0].id_warehouse,
      noteToCreate.valores[0].id_articulo,
      noteToCreate.valores[0].codigo,
      noteToCreate.valores[0].cantidad,
      noteToCreate.tipo_nota,
      noteToCreate.concepto,
      noteToCreate.valores[0].precio,
      noteToCreate.valores[0].subtotal,
      noteToCreate.valores[0].descuento,
      noteToCreate.total,
      noteToCreate.createAt,
      noteToCreate.dueDate,
      parseInt(this.user!)
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result) {
        this.spinnerService.hide();
        this.alertsService.showSuccessfullAlert("Credit note has been created successfully")
        .then((alertConfirm) => {
          if (alertConfirm.isConfirmed) {
            if (billyNote) {
              this.saveBillyNote(result, billyNote);
            } else {
              this.alertsService.refresh("/card", "/sales")
            }
          }
        });
      } else {
        this.spinnerService.hide();
        this.alertsService.showErrorAlert("Something was wrong")
      }
    });
  }

  saveBillyNote(result: any, billyNote: IBillyNotesToGraphQ) {
    this.quotation
    .setBillyNote(
      result.id_nota,
      billyNote.data.id,
      billyNote.data.attributes.creditNoteNumber
    )
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((data) => {
      if (data) {
        this.alertsService.refresh("/card", "/sales")
      }
    });
  }

  calculation() {
    //Variables registerForm
    let price;
    let quantity;
    let discount;
    let tax;
    let subtotal;

    //Variables valuesForm
    let precioVal;
    let cantidadVal;
    let subtotalVal;
    //????
    let calcSubTax;

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.registerForm.get("tax_incl")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          tax = this.registerForm.value.tax;
        } else {
          tax = this.rowsArr.value[i].article.tax[0].value;
        }
      } else {
        tax = 0;
      }
      //changing values
      price = this.rowsArr.at(i).get('price')?.getRawValue();
      quantity = this.rowsArr.at(i).get('quantity')?.getRawValue();
      discount = this.rowsArr.value[i].discount;

      //original values
      precioVal = this.rowsArr.value[i].price2;
      cantidadVal = this.rowsArr.value[i].quantity2;
      subtotalVal = this.rowsArr.value[i].subtotal2;

      if (tax >= 0) {
        if (discount != 0) {
          subtotal = quantity * price;
          discount = (discount / 100) * subtotal;
          subtotal = subtotal - discount;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            tax = (tax / 100) * subtotal;
          } else {
            tax = tax * subtotal;
          }
          //positive price calculation
          this.rowsArr.value[i].precioVec = price - precioVal;
          //positive quantity calculation
          this.rowsArr.value[i].cantidadVec = quantity - cantidadVal;
          //subtotal calculation
          this.rowsArr.value[i].subtotal = subtotal + tax;
          //positive subtotal calculation
          calcSubTax = subtotal + tax;
          this.rowsArr.value[i].subtotalVec = calcSubTax - subtotalVal;

          if (this.rowsArr.value[i].cantidadVec === 0) {
            this.rowsArr.value[i].cantidadVec = cantidadVal;
          }
          if (this.rowsArr.value[i].precioVec === 0) {
            this.rowsArr.value[i].precioVec = precioVal;
          }

          //subtotal without tax
          this.rowsArr.value[i].subtotal3 = subtotal;
        } else {
          subtotal = quantity * price;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            tax = (tax / 100) * subtotal;
          } else {
            tax = tax * subtotal;
          }
          //positive price calculation
          this.rowsArr.value[i].precioVec = price - precioVal;
          //positive quantity calculation
          this.rowsArr.value[i].cantidadVec = quantity - cantidadVal;
          //subtotal calculation
          this.rowsArr.value[i].subtotal = price * quantity + tax;
          //positive subtotal calculation
          calcSubTax = price * quantity + tax;
          this.rowsArr.value[i].subtotalVec = calcSubTax - subtotalVal;

          if (this.rowsArr.value[i].cantidadVec === 0) {
            this.rowsArr.value[i].cantidadVec = cantidadVal;
          }
          if (this.rowsArr.value[i].precioVec === 0) {
            this.rowsArr.value[i].precioVec = precioVal;
          }

          //subtotal without tax
          this.rowsArr.value[i].subtotal3 = price * quantity;
        }
      }
    }
    this.totalQuot = 0;
    this.totalQuot2 = 0;
    this.totalCalc = 0;
    this.taxVer = 0;

    for (let i = 0; i < this.rowsArr.length; i++) {
      //total without tax
      this.totalCalc = this.totalCalc + this.rowsArr.value[i].subtotal3;
      //invoice total
      this.totalQuot = this.totalQuot + this.rowsArr.value[i].subtotal;
      //negative invoice total
      this.totalQuot2 = this.totalQuot2 + this.rowsArr.value[i].subtotalVec;
      //tax calculated
      this.taxVer = this.totalQuot - this.totalCalc;
    }
  }

  private _filterC(value: string): ICustomer[] {
    const filterValueC = value.toString().toLowerCase();
    return this.CustomersList.filter((option) =>
      option.doc_nit.toLowerCase().toString().includes(filterValueC) ||
      option.nombre.toLowerCase().toString().includes(filterValueC)
    );
  }

  private _filterA(value: string): IArticles[] {
    const filterValueA = value.toString().toLowerCase();
    return this.ArticleList.filter((option) =>
      option.descripcion.toLowerCase().toString().includes(filterValueA) ||
      option.codigo!.toLowerCase().toString().includes(filterValueA)
    );
  }

  displayFnArticle(article: any) {
    return article && article ? article.descripcion : undefined;
  }

  displayFnCustomer(customer: any) {
    return customer && customer ? customer.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
    }, 3000);
  }

  openDialogVerCredit(id_factura: number) {
    this.dialog.open(DialogComponent, {
      data: { vercredit: id_factura },
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
