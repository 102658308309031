import { Component, Input, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ProviderService } from "../../../../../@pages/provider/provider.service";
import { MatDialog } from "@angular/material/dialog";
import { InventoryService } from "../../../../../@pages/inventory/inventory.service";
import { PurchasesService } from "../../../../../@pages/purchases/purchases.service";
import { Subject, Observable } from "rxjs";
import { IPurchasesInvoices } from "../../../../../@interfaces/purchaseInvoice.interface";
import { IProvider } from "../../../../../@interfaces/provider.interface";
import { IArticles } from "../../../../../@interfaces/article.interface";
import { takeUntil, startWith } from "rxjs/operators";
import { RegisterPurchaseService } from "./register-purchase.service";
import { ICities } from "../../../../../@interfaces/cities.interface";
import { map } from "rxjs/internal/operators/map";
import { TributaryService } from "../../../../../@pages/tributary/tributary.service";
import { ConverseFilesService } from "../../../services/converse-files.service";
import { FunctionsSiigoService } from "../../../services/functions-siigo.service";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { AlertsService } from '../../../services/alerts.service';
import { ArticleCostService } from "../../../services/article-cost.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { IStorageCompany } from "src/app/@interfaces/company.interface";

@Component({
  selector: "app-register-purchase",
  templateUrl: "./register-purchase.component.html",
  styleUrls: ["./register-purchase.component.css"],
})
export class RegisterPurchaseComponent implements OnInit {
  dateToday = new Date();
  @Input() uvtValue = 0;
  @Input() XMLFIle: string = "";
  @Input() totalPurchase: number = 0;
  @Input() totalWithoutTax: number = 0;
  @Input() totalTax: number = 0;
  @Input() withholdingTax: number = 0;
  @Input() icaTax: number = 0;
  @Input() customerCity = "";
  @Input() providerCity = "";
  @Input() newProvider: UntypedFormControl = this.fb.control(
    "",
    Validators.required
  );
  @Input() purchaseForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        warehouse: ["", Validators.required],
        article: ["", Validators.required],
        quantity: [, [Validators.required, Validators.min(0.001)]],
        price: [, [Validators.required, Validators.min(0.001)]],
        discount: [0, Validators.required],
        subtotal: [0, Validators.required],
      }),
    ]),
    written_invoice: ["", Validators.required],
    tax_include: false,
    clause: false,
    tax: [0, [Validators.maxLength(2), Validators.min(0)]],
    date: [this.dateToday, Validators.required],
    dueDate: [this.dateToday, Validators.required],
  });
  get rowsArr() {
    return this.purchaseForm.get("itemRows") as UntypedFormArray;
  }
  providersList: Array<IProvider> = [];
  articleList: Array<IArticles> = [];
  articles = [] as IArticles[];
  providers = [] as IProvider[];
  setPurchaseInvoice = {} as IPurchasesInvoices;
  warehouseList: IWarehouse[] = [];
  companyId = '';
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  citiesList: ICities[] = [];
  citiesProviderForm: UntypedFormControl = this.fb.control(
    "",
    Validators.required
  );
  filteredOptionsProviderCities: Observable<ICities[]> | undefined;
  citiesCustomerForm: UntypedFormControl = this.fb.control(
    "",
    Validators.required
  );
  filteredOptionsCustomerCities: Observable<ICities[]> | undefined;
  companyObject = {} as IStorageCompany;

  constructor(
    private spinnerService: NgxSpinnerService,
    private providersService: ProviderService,
    private article: InventoryService,
    public dialog: MatDialog,
    public purchasesService: PurchasesService,
    private registerPurchaseService: RegisterPurchaseService,
    private fb: UntypedFormBuilder,
    private tributaryService: TributaryService,
    private converseFilesService: ConverseFilesService,
    private functionsSiigoService: FunctionsSiigoService,
    private warehouse: WarehouseService,
    private alertsService: AlertsService,
    private articleCostService: ArticleCostService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getAuthValues();
    this.getInputValues();
    this.citiesList = await this.getCities();
    this.setCitiesForm(this.citiesList);
    this.filteredArrays();
    this.data();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  data() {
    this.warehouse
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    });
  }

  getCities() {
    return new Promise<any>((resolve, reject) => {
      const country_id = this.companyObject.countries[0].id_country;
      this.tributaryService
      .getCitiesByCompany(parseInt(country_id!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      });
    });
  }

  filteredArrays() {
    this.filteredOptionsCustomerCities =
    this.citiesCustomerForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
    this.filteredOptionsProviderCities =
    this.citiesProviderForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
  }

  onCitiesCustomer(event: any) {
    this.customerCity = event.option.value.name;
    this.setCitiesForm(this.citiesList);
    this.calculation();
  }

  onCitiesProvider(event: any) {
    this.providerCity = event.option.value.name;
    this.setCitiesForm(this.citiesList);
    this.calculation();
  }

  setCitiesForm(citiesList: ICities[]) {
    if (this.customerCity) {
      const setCustomerCity = citiesList.filter(
        (city) =>
        this.converseFilesService.removeAccents(
          city.name.toString().toLocaleLowerCase()
        ) ===
        this.converseFilesService.removeAccents(
          this.customerCity.toString().toLocaleLowerCase().trim()
        )
      );
      if (setCustomerCity.length > 0) {
        this.citiesCustomerForm.setValue(setCustomerCity[0]);
      }
    }
    if (this.providerCity) {
      const setProviderCity = citiesList.filter(
        (city) =>
        this.converseFilesService.removeAccents(
          city.name.toString().toLocaleLowerCase()
        ) ===
        this.converseFilesService.removeAccents(
          this.providerCity.toString().toLocaleLowerCase().trim()
        )
      );
      if (setProviderCity.length > 0) {
        this.citiesProviderForm.setValue(setProviderCity[0]);
      }
    }
  }

  getInputValues() {
    this.newProvider.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any) => {
      if (!value.__typename) {
        this.providersService
        .searchProvider(value, null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.providersList = result.providers;
        });
      }
    });
    this.rowsArr.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any[]) => {
      value.forEach((element) => {
        if (element.article) {
          if (typeof element.article === "string" || typeof element.article === "number") {
            this.article
            .searchArticle(element.article, null, null, null)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result: any) => {
              this.articleList = result.articles;
            });
          }
        }
      });
    });
  }

  onProviderSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      this.calculation();
    }
  }

  addNewRow() {
    if (this.purchaseForm.invalid === true) {
      return;
    }
    const newRow = this.fb.group({
      warehouse: ['', Validators.required],
      article: ['', Validators.required],
      quantity: ['0', Validators.required],
      price: [0, Validators.required],
      discount: [0, Validators.required],
      subtotal: [0, Validators.required],
    });
    this.rowsArr.push(newRow);
    this.calculation();
  }

  deleteRow(rowIndex: number) {
    if (this.rowsArr.length > 1) {
      this.rowsArr.removeAt(rowIndex);
    } else if (this.rowsArr.length === 1) {
      this.alertsService.showWarningAlert("You should not remove the last row");
    }
    this.calculation();
  }

  validRegister() {
    if (this.purchaseForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!");
      this.spinnerService.hide();
      return false;
    } else if (this.rowsArr.length > 12) {
      this.alertsService.showErrorAlert(
        "The purchase cannot have more than 12 items, please delete",
        " " + (this.rowsArr.length - 12)
      );
      this.spinnerService.hide();
      return false;
    } else {
      return true;
    }
  }

  validProvider(variables: IPurchasesInvoices) {
    if (!variables.provider[0].id_provider) {
      this.alertsService.showErrorAlert("This provider does not exist");
      this.spinnerService.hide();
      return;
    }
  }

  validArticle(variables: IPurchasesInvoices) {
    if (!variables.purchases[0].codigo) {
      this.alertsService.showErrorAlert("This article does not exist");
      this.spinnerService.hide();
      return;
    }
  }

  register() {
    const validations = this.validRegister();
    if (!validations) {
      return;
    }else {
      this.calculation();
      if ((this.citiesProviderForm.invalid || this.citiesCustomerForm.invalid) && (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true")) {
        this.alertsService.showErrorAlert("This city does not exist");
        this.spinnerService.hide();
        return;
      } else {
        this.spinnerService.show();
        const variables = this.registerPurchaseService.setQuotationData(
          this.purchaseForm,
          this.rowsArr,
          this.newProvider
        );
        this.validProvider(variables!);
        this.validArticle(variables!);
        this.purchasesService
        .createPurchase(
          variables.contpurchase![0].written_invoice,
          variables.provider[0].id_provider!,
          variables.tax,
          this.withholdingTax,
          this.icaTax,
          this.customerCity,
          this.providerCity,
          variables.purchases[0].id_warehouse,
          variables.purchases[0].codigo,
          variables.purchases[0].quantity,
          variables.purchases[0].price,
          variables.purchases[0].discount,
          variables.purchases[0].subtotal,
          variables.purchases[0].total,
          variables.createdAt,
          variables.dueDate,
          variables.tax_incl!,
          variables.clause,
          this.XMLFIle,
          parseInt(this.user!)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result.num_invo !== "purchaseExist") {
            this.spinnerService.hide();
            this.alertsService.showSuccessfullAlert("Purchase created successfully!")
            .then((result) => {
              if (result.isConfirmed) {
                this.alertsService.refresh("/card", "/purchases")
              }
            });
          } else if (result.num_invo === "purchaseExist") {
            this.spinnerService.hide();
            this.alertsService.showErrorAlert("This purchase already exist");
          }
        },(error) => {
          this.spinnerService.hide();
        });
      }
    }
  }

  async calculation() {
    let priceCal = 0;
    let quantityCal = 0;
    let discountCal = 0;
    let taxCal = 0;
    let subsubtotal = 0;
    let totalWithoutTax = 0;
    let totalTax = 0;
    const contributorID = parseInt(
      this.newProvider.value.contributorType.id_contributor
    );

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.purchaseForm.get("tax_include")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          taxCal = this.functionsSiigoService.getContributorTax(
            contributorID,
            this.purchaseForm.value.tax
          );
        } else {
          taxCal = this.functionsSiigoService.getContributorTax(
            contributorID,
            this.rowsArr.value[i].article.tax[0].value
          );
        }
      } else {
        taxCal = 0;
      }
      priceCal = this.rowsArr.value[i].price;
      quantityCal = this.rowsArr.value[i].quantity;
      discountCal = this.rowsArr.value[i].discount;
      if (taxCal) {
        if (discountCal != 0) {
          subsubtotal = quantityCal * priceCal;
          discountCal = (discountCal / 100) * subsubtotal;
          subsubtotal = subsubtotal - discountCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = subsubtotal + taxCal;
          totalTax = totalTax + taxCal;
          totalWithoutTax = totalWithoutTax + subsubtotal;
        } else {
          subsubtotal = quantityCal * priceCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = priceCal * quantityCal + taxCal;
          totalTax = totalTax + taxCal;
          totalWithoutTax = totalWithoutTax + subsubtotal;
        }
      } else if (discountCal != 0) {
        subsubtotal = quantityCal * priceCal;
        discountCal = (discountCal / 100) * subsubtotal;
        this.rowsArr.value[i].subtotal = subsubtotal - discountCal;
        totalWithoutTax = totalWithoutTax + subsubtotal - discountCal;
      } else {
        subsubtotal = quantityCal * priceCal;
        this.rowsArr.value[i].subtotal = subsubtotal;
      }
    }
    this.totalPurchase = 0;
    for (let i = 0; i < this.rowsArr.length; i++) {
      this.totalPurchase = this.totalPurchase + this.rowsArr.value[i].subtotal;
    }
    this.totalTax = totalTax;
    this.totalWithoutTax = this.totalPurchase - this.totalTax;
    if (this.companyObject.countries[0].name === "Colombia") {
      await this.calculateTaxes();
    }
  }

  async calculateTaxes() {
    const baseTax = await this.registerPurchaseService.getTaxBase();
    this.uvtValue = baseTax[0].value;
    this.withholdingTax =
      this.registerPurchaseService.calculationWithholdingTax(
        this.newProvider,
        this.totalWithoutTax,
        this.uvtValue,
        this.rowsArr,
        this.purchaseForm.value.clause
      );
    const resultCalculateIca =
      await this.registerPurchaseService.calculationIcaTax(
        this.customerCity,
        this.providerCity,
        this.newProvider,
        this.totalWithoutTax,
        this.uvtValue,
        this.rowsArr
      );
    this.icaTax = resultCalculateIca.icaTax;
    this.customerCity = resultCalculateIca.city;
    this.setCitiesForm(this.citiesList);
  }

  displayFnArticle(article: any) {
    return article && article
      ? article.descripcion +
        new String(` - (`) +
        article.tax[0].value +
        new String(`)`)
      : undefined;
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  textValid(text: string, index?: number) {
    return (
      this.purchaseForm.get(text)?.invalid &&
      this.purchaseForm.get(text)?.touched
    );
  }

  arrayvalid(text: string) {
    return this.rowsArr.get(text)?.invalid && this.rowsArr.get(text)?.touched;
  }

  taxValid(tax: boolean) {
    return tax === true ? true : false;
  }

  displayFnCity(city: any) {
    return city && city ? city.name : undefined;
  }

  _filterC(value: string): ICities[] {
    const filterValueC = value.toString().toLowerCase();
    return this.citiesList.filter((option) =>
      option.name.toLowerCase().toString().includes(filterValueC)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
