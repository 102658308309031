import { Injectable } from "@angular/core";
import { PurchasesService } from '../../../@pages/purchases/purchases.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IPurchaseInvoice } from "src/app/@interfaces/purchaseInvoice.interface";
import { AlertsService } from './alerts.service';
import { ProviderService } from '../../../@pages/provider/provider.service';
import { IProvider } from "src/app/@interfaces/provider.interface";
import { IImportPurchases } from "src/app/@interfaces/Import-files/import-purchases.interface";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { ArticleCostService } from './article-cost.service';
import { CookieAuthService } from "../../storage-variables/cookie-auth.service";

@Injectable({
    providedIn: 'root'
})

export class ImportPurchasesService {

    unsubscribe$ = new Subject();

    constructor(
        private purchasesService: PurchasesService,
        private alertsService: AlertsService,
        private providerService: ProviderService,
        private articleCostService: ArticleCostService,
        private cookieAuthService: CookieAuthService
    ) {}

    getProvider(nit: string) {
        return new Promise<IProvider>((resolve, reject) => {
          this.providerService
          .getProviderByNit(nit.toString())
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((provider: IProvider) => {
            if (provider) {
              resolve(provider);
            }
          },(error) => {
            this.alertsService.showErrorAlert(`This provider id does not exist`, " : " + nit)
            .then((confirm) => {
                if (confirm.isConfirmed) {
                    reject(new Error(`This provider id does not exist` + " : " + nit));
                }
            })
          });
        });
      }

    createPurchase(purchaseObject: IImportPurchases, customerCity: string) {
        const user = this.cookieAuthService.getUserId!;
        const companyObject = this.cookieAuthService.getCompanyObject;
        return new Promise<string>(async (resolve, reject) => {
            if (purchaseObject.articleCode.length > 0) {
                this.purchasesService
                .createPurchase(
                    purchaseObject.purchaseID,
                    parseInt(purchaseObject.provider.id_provider!.toString()),
                    purchaseObject.taxPercent,
                    purchaseObject.withholding,
                    purchaseObject.ica,
                    companyObject!.countries[0].name === 'Colombia' ? customerCity : '',
                    companyObject!.countries[0].name === 'Colombia' ? purchaseObject.provider.city! : '',
                    purchaseObject.warehouseID,
                    purchaseObject.articleCode,
                    purchaseObject.quantity,
                    purchaseObject.price,
                    purchaseObject.discount,
                    purchaseObject.subtotal,
                    purchaseObject.total,
                    purchaseObject.date,
                    purchaseObject.dueDate,
                    'false',
                    'false',
                    '',
                    parseInt(user!)
                )
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((purchase: IPurchaseInvoice) => {
                    if (purchase.num_invo === "purchaseExist") {
                        resolve("Item id:" + " " + "[ " + purchaseObject.number.toString() + " ]" + " >>>>>>>>>>>> " + "Already Exitst");
                    }else{
                        resolve("Item id:" + " " + "[ " + purchaseObject.number.toString() + " ]" + " >>>>>>>>>>>> " + "OK");
                    }
                },(error) => {
                    this.alertsService.showErrorAlert("Error uploading purchase", ': ' + purchaseObject.number + error)
                    .then((confirm) => {
                        if(confirm.isConfirmed) {
                            reject(error);
                        }
                    })
                })
            } else {
                this.alertsService.showErrorAlert("Error constructing the purchase", ': ' + purchaseObject.number)
                .then((confirm) => {
                    if (confirm.isConfirmed) {
                        reject("Error constructing the invoice");
                    }
                })
            }
        })
    }
}
