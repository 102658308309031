
<div class="button-div position-absolute">
    <a><button mat-raised-button class="warn" (click)="openDialog()">{{'Config' | translate}}</button></a>
</div>
<div class="d-flex justify-content-center pt-1">
    <app-date-filter (datepickerRange)="setDateSelected($event)"></app-date-filter>
</div>
<div class="header">
    <h3>{{'PLEASE SELECT THE REPORT TO DOWNLOAD' | translate}}</h3>
</div>
<div class="container">
    <div class="main">
        <table class="table table-bordered">
            <tbody>
                <tr *ngFor="let reports of reportList; let i = index">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{reports | translate}}
                    </td>
                    <td>
                        <a class="goLink" (click)="select(reports)">
                            {{'Download' | translate}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 

<table class="table table-striped" id="header" hidden>
    <thead>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo">{{companyName.toLocaleUpperCase()}}</td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo">{{modelName.toLocaleUpperCase() | translate}}</td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo"></td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo"></td>
        </tr>
    </thead>
</table>

<table class="table table-striped" id="inventoryMovements" hidden>
    <thead>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo">{{companyName.toLocaleUpperCase()}}</td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo">{{modelName.toLocaleUpperCase() | translate}}</td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo"></td>
        </tr>
        <tr>
            <td hidden [colSpan]="colspanHeaderSiigo"></td>
        </tr>
    </thead>
    <thead>
        <tr>
            <th>{{'LINEA' | translate}}</th>
            <th>{{'GRUPO' | translate}}</th>
            <th>{{'PRODUCTO' | translate}}</th>
            <th>{{'LIN' | translate}}</th>
            <th>{{'GRU' | translate}}</th>
            <th>{{'ELEM' | translate}}</th>
            <th>{{'NOMBRE ELEMENTO' | translate}}</th>
            <th>{{'REFERENCIA' | translate}}</th>
            <th>{{'UNIDAD' | translate}}</th>
            <th>{{'MARCA' | translate}}</th>
            <th>{{'DESCRIPCION' | translate}}</th>
            <th>{{'DOCUMENTO' | translate}}</th>
            <th>{{'FECHA' | translate}}</th>
            <th>{{'BODEGA' | translate}}</th>
            <th>{{'UBICACION' | translate}}</th>
            <th>{{'UNITARIO' | translate}}</th>
            <th>{{'CANT. ANTERIOR' | translate}}</th>
            <th>{{'VALOR ANTERIOR' | translate}}</th>
            <th>{{'ENTRADA' | translate}}</th>
            <th>{{'ENTRADAS CLAS.' | translate}}</th>
            <th>{{'VALOR ENTRADAS' | translate}}</th>
            <th>{{'SALIDAS' | translate}}</th>
            <th>{{'SALIDAS CLAS.' | translate}}</th>
            <th>{{'VALOR SALIDAS' | translate}}</th>
            <th>{{'CANTIDAD' | translate}}</th>
            <th>{{'ACUM CANTIDAD' | translate}}</th>
            <th>{{'VALOR' | translate}}</th>
            <th>{{'ACUM VALOR' | translate}}</th>
            <th>{{'ENTRADA CAN2' | translate}}</th>
            <th>{{'SALIDA CAN2' | translate}}</th>
            <th>{{'CANTIDAD2' | translate}}</th>
            <th>{{'ACUM CANTIDAD2' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let item of inventoryMovementArray">
            <td>{{item.linie}}</td>
            <td>{{item.group}}</td>
            <td>{{item.product}}</td>
            <td>{{item.lin}}</td>
            <td>{{item.gro}}</td>
            <td>{{item.elem}}</td>
            <td>{{item.element_name}}</td>
            <td>{{item.reference}}</td>
            <td>{{item.unit}}</td>
            <td>{{item.trademark}}</td>
            <td>{{item.description}}</td>
            <td>{{item.document}}</td>
            <td>{{item.date | exceldate}}</td>
            <td>{{item.warehouse}}</td>
            <td>{{item.location}}</td>
            <td>{{item.unitary}}</td>
            <td>{{item.quant_previus}}</td>
            <td>{{item.value_previus}}</td>
            <td>{{item.entries}}</td>
            <td>{{item.entries_clas}}</td>
            <td>{{item.entries_values}}</td>
            <td>{{item.exits}}</td>
            <td>{{item.exits_clas}}</td>
            <td>{{item.exits_values}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.acc_quantity}}</td>
            <td>{{item.value}}</td>
            <td>{{item.acc_value}}</td>
            <td>{{item.entry_quant2}}</td>
            <td>{{item.exit_quant2}}</td>
            <td>{{item.quantity2}}</td>
            <td>{{item.acc_quantity2}}</td>
        </tr>
    </tbody>
</table>

<app-siigo-table [chartAccountsData]="chartAccounts" [headerDateRange]="headerDate"></app-siigo-table>
